import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { routeTo } from '@app/helpers/RoutesHelper';
import { getUserData } from '@app/helpers/UserHelper';

import ROUTES from '@app/router/Routes';

import Permission from './Permission';

interface Props extends RouteProps {
    permissions?: string[];
}
const AuthRoute = (props: Props) => {
    const { permissions } = props;
    const user = getUserData();

    /* This is a specific use case for 'Accueil' role only
    In the future, autouser will provide us that logic instead */
    const handleRedirect = () => {
        if (user.user.role.slug === 'accueil') return <Redirect to={routeTo(ROUTES.CUSTOMERS.PATH)} />;

        return <Redirect to={routeTo(ROUTES.DASHBOARD.PATH)} />;
    };

    return (
        <Permission permissions={permissions} fallback={() => handleRedirect()}>
            <Route {...props} />
        </Permission>
    );
};

AuthRoute.defaultProps = {
    permissions: [],
};

export default AuthRoute;
