import classNames from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useFadeOut from '@app/hooks/useFadeOut';
import useFilters from '@app/hooks/useFilters';

import { CrewActivity, getDashboardCrewActivityForManager } from '@app/crud/dashboard/dashboard.crud';

import { useFetch } from '@app/hooks';
import { DashboardContext } from '@app/pages/dashboard/DashboardProvider';
import { getTransformation, linkToLeadWithFilters } from '@app/pages/dashboard/helpers/DashboardHelper';
import ROUTES from '@app/router/Routes';
import { actions } from '@app/store/modal/modal.store';

import OverlayTooltip from '@app/partials/content/OverlayTooltip';

interface Props {
    data?: any;
    dealership?: any;
    timelineClassName?: string;
    itemClassName?: string;
    displayTransformationRate?: boolean;
    displayOverlayInfo?: boolean;
}

const DashboardActivityTimeline = ({
    data: baseData,
    dealership,
    timelineClassName,
    itemClassName,
    displayTransformationRate = false,
    displayOverlayInfo = false,
}: Props) => {
    const dispatch = useDispatch();
    const { onBottom, scrollable, handleScroll, fadeOutRef } = useFadeOut();
    const [theData, setTheData] = useState(baseData);

    const { overriddenParams } = useContext(DashboardContext);

    const {
        data: fetchData,
        loading,
        fetch,
    } = useFetch({
        fetchAction: getDashboardCrewActivityForManager,
    });
    useEffect(() => {
        if (!baseData && fetchData) {
            setTheData(fetchData);
        }
    }, [fetchData]);

    const history = useHistory();
    const { updateFilters } = useFilters({
        name: `leads_online_action-to-do`,
    });

    const { updateFilters: updateFiltersClosed } = useFilters({
        name: `leads_online_close`,
    });

    const { updateFilters: updateFiltersAll } = useFilters({
        name: `leads_online_all`,
    });

    const { updateFilters: updateApvFilters } = useFilters({
        name: `leads_apv_action_to_do`,
    });

    useEffect(() => {
        if (!baseData && overriddenParams) {
            fetch(overriddenParams);
        }
    }, [overriddenParams]);

    useEffect(() => {
        setTheData(baseData);
    }, [baseData]);

    const showAgenda = (user: CrewActivity) => {
        dispatch(
            actions.modalChange('agenda_details', {
                user,
            }),
        );
    };

    const timelines = useMemo(
        () =>
            theData
                ?.sort?.((a: CrewActivity, b: CrewActivity) => {
                    if (a?.appointment || a?.actionsToDo || b?.appointment || b?.actionsToDo) {
                        return 1;
                    }
                    return 0;
                })
                ?.map?.((timeline: CrewActivity) => {
                    const { processedOverLast30Days } = timeline;
                    const closedLost = processedOverLast30Days.find(
                        (processed: { count: number; status: string }) => processed?.status === 'closed_lost',
                    );
                    const closedWon = processedOverLast30Days.find(
                        (processed: { count: number; status: string }) => processed?.status === 'closed_won',
                    );
                    const closedTotal = (closedLost?.count || 0) + (closedWon?.count || 0);
                    const closedLostContent = (
                        <>
                            <span className="font-weight-semibold">{closedTotal} </span>
                            {timeline.isAPV ? (
                                <FormattedHTMLMessage
                                    id="DASHBOARD.ACTIVITY.LEAD.APV.LAST.THIRTY.DAYS"
                                    values={{ number: closedTotal }}
                                />
                            ) : (
                                <FormattedHTMLMessage
                                    id="DASHBOARD.ACTIVITY.LEADS.CLOSED"
                                    values={{ count: closedTotal }}
                                />
                            )}
                            .
                            {!timeline.isAPV && <FormattedHTMLMessage id="DASHBOARD.ACTIVITY.LEADS.LAST.THIRTY.DAYS" />}
                        </>
                    );

                    const dealershipFilterValue = dealership?.usualName || '';
                    const userFilterValue = `${timeline.firstname} ${timeline.lastname}`;

                    return (
                        <div key={timeline.id} className={classNames('dashboard-activity__row', itemClassName)}>
                            <div>
                                <span className="font-weight-semibold">
                                    {timeline.firstname} {timeline.lastname}
                                    {timeline.role?.shortName && <> ({timeline.role?.shortName})</>}
                                </span>
                            </div>
                            <div className="cursor-pointer text-primary">
                                <Button
                                    className="d-flex align-items-center mr-1 py-1 px-1"
                                    variant={timeline.actionsToDo > 0 ? 'danger' : 'outline-secondary'}
                                    size="sm"
                                    onClick={() =>
                                        timeline.isAPV
                                            ? linkToLeadWithFilters(
                                                  history,
                                                  true,
                                                  updateApvFilters,
                                                  dealershipFilterValue,
                                                  userFilterValue,
                                                  'action_to_do',
                                                  ROUTES.APV_LEADS.PATH,
                                              )
                                            : linkToLeadWithFilters(
                                                  history,
                                                  true,
                                                  updateFilters,
                                                  dealershipFilterValue,
                                                  userFilterValue,
                                              )
                                    }
                                >
                                    <i className="las la-hand-pointer" />
                                    <FormattedHTMLMessage
                                        id={
                                            timeline.isAPV
                                                ? 'DASHBOARD.ACTIVITY.USER.ACTIONS_APV'
                                                : 'DASHBOARD.ACTIVITY.USER.ACTIONS'
                                        }
                                        values={{
                                            number: timeline.actionsToDo,
                                        }}
                                    />
                                </Button>
                            </div>
                            <Button
                                className="d-flex align-items-center mt-2 my-1 py-1 px-1"
                                onClick={() => showAgenda(timeline)}
                                variant={timeline.appointment > 0 ? 'primary' : 'outline-secondary'}
                                size="sm"
                            >
                                <i className="las la-calendar" />
                                <FormattedHTMLMessage
                                    id="DASHBOARD.ACTIVITY.USER.APPOINTMENTS"
                                    values={{
                                        number: timeline.appointment,
                                    }}
                                />
                            </Button>
                            {displayOverlayInfo && timeline?.leadsInProgress > 0 && (
                                <div>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                                    <a
                                        className="text-primary"
                                        onClick={() =>
                                            linkToLeadWithFilters(
                                                history,
                                                true,
                                                updateFiltersAll,
                                                dealershipFilterValue,
                                                userFilterValue,
                                                'all',
                                            )
                                        }
                                    >
                                        <FormattedHTMLMessage
                                            id="DASHBOARD.ACTIVITY.LEADS.IN_PROGRESS"
                                            values={{ count: timeline.leadsInProgress }}
                                        />
                                    </a>
                                </div>
                            )}
                            <div>
                                {closedTotal !== 0 ? (
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                                    <a
                                        className="text-primary"
                                        onClick={() => {
                                            linkToLeadWithFilters(
                                                history,
                                                true,
                                                updateFiltersClosed,
                                                dealershipFilterValue,
                                                userFilterValue,
                                                'close',
                                            ).then((r) => r);
                                        }}
                                    >
                                        {closedLostContent}
                                    </a>
                                ) : (
                                    closedLostContent
                                )}
                                {displayOverlayInfo && !timeline.isAPV && (
                                    <>
                                        &nbsp;
                                        <OverlayTooltip
                                            id="activity-information"
                                            label="DASHBOARD.ACTIVITY.INFORMATION"
                                            position="top"
                                            show
                                        >
                                            <i className="las la-info-circle" />
                                        </OverlayTooltip>
                                    </>
                                )}
                            </div>
                            {displayTransformationRate && !timeline.isAPV && (
                                <div>
                                    (
                                    <span className="font-weight-semibold">
                                        {getTransformation(closedWon?.count, closedLost?.count)} %{' '}
                                    </span>
                                    <FormattedHTMLMessage id="DASHBOARD.ACTIVITY.RANKING.TRANSFORMATION" />)
                                </div>
                            )}
                            <div className="clearfix" />
                        </div>
                    );
                }),
        [theData],
    );

    if (loading) {
        return <DashboardActivityTimeline.Skeleton />;
    }

    return (
        <>
            <div
                className={classNames('dashboard-activity__timeline', timelineClassName, {
                    'dashboard-activity__timeline--scrollable': scrollable,
                })}
                ref={fadeOutRef}
                onScroll={handleScroll}
            >
                {theData && theData.length > 0 ? (
                    timelines
                ) : (
                    <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                        <span className="text-center text-dark-50 font-weight-semibold font-size-lg">
                            <FormattedHTMLMessage id="DASHBOARD.NO.ACTIVITY" />
                        </span>

                        <Image
                            className="mt-8"
                            src="/media/pages/dashboard/dashboard-aucun-rdv.svg"
                            alt="icon aucun rdv"
                        />
                    </div>
                )}
            </div>
            {!onBottom && <div className="dashboard-priorities__fadeout" />}
        </>
    );
};

DashboardActivityTimeline.Skeleton = () => <Skeleton borderRadius="10px" className="mt-10 h-100" />;

export default DashboardActivityTimeline;
