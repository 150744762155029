import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';

import { DashboardContext } from '../../DashboardProvider';
import { getStatsSortValues } from '../../helpers/DashboardHelper';

const DashboardPerfState = ({ detail, leadType }) => {
    const { params } = useContext(DashboardContext);

    return (
        <div className="d-flex flex-nowrap font-size-sm font-weight-semibold">
            <Link
                className="text-primary text-nowrap"
                to={{
                    pathname: routeTo(ROUTES.LEADS_ONLINE.PATH, {
                        statusType: 'all',
                    }),
                    state: getStatsSortValues({
                        ...params,
                        leadType,
                    }),
                }}
            >
                <u>
                    {detail?.inProgress ?? 0} <FormattedMessage id="DASHBOARD.ONLINE_PERF.IN_PROGRESS" />
                </u>
            </Link>
            <span className="mx-1">,</span>
            <Link
                className="text-danger text-nowrap"
                to={{
                    pathname: routeTo(ROUTES.LEADS_ONLINE.PATH, {
                        statusType: 'close',
                    }),
                    state: getStatsSortValues({
                        ...params,
                        leadStatus: 'closed_lost',
                        leadType,
                    }),
                }}
            >
                <u>
                    {detail?.lost ?? 0} <FormattedMessage id="DASHBOARD.ONLINE_PERF.LOST" />
                </u>
            </Link>
            <span className="mx-1">,</span>
            <Link
                className="text-success text-nowrap"
                to={{
                    pathname: routeTo(ROUTES.LEADS_ONLINE.PATH, {
                        statusType: 'close',
                    }),
                    state: getStatsSortValues({
                        ...params,
                        leadStatus: 'closed_won',
                        leadType,
                    }),
                }}
            >
                <u>
                    {detail?.won ?? 0} <FormattedMessage id="DASHBOARD.ONLINE_PERF.WON" />
                </u>
            </Link>
        </div>
    );
};

export default DashboardPerfState;
