import ApiGateway from '@app/helpers/AxiosHelper';

const { REACT_APP_LEAD_URL, REACT_APP_AUTOUSER_URL } = process.env;

export function getDefaultSchedule(userId) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/schedule/search-default-schedule`, {
        params: {
            userId,
        },
    });
}

export function updateDefaultSchedule(userId, data) {
    return ApiGateway.post(`${REACT_APP_LEAD_URL}/schedule/add-default-schedule`, {
        ...data,
        userId,
    });
}

export function updateAvailability(userId, data) {
    return ApiGateway.post(`${REACT_APP_LEAD_URL}/schedule/add-availability`, {
        ...data,
        userId,
    });
}

export function searchAvailabilities(data) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/schedule/search-availabilities`, {
        params: data,
    });
}

export function getHistory(data) {
    return ApiGateway.get(`${REACT_APP_AUTOUSER_URL}/users/history`, {
        params: {
            ...data,
        },
    });
}
