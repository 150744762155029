import classNames from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import useFetch from '@app/hooks/useFetch';

import { routeTo } from '@app/helpers/RoutesHelper';

import { getDashboardPendingCounters } from '@app/crud/dashboard/dashboard.crud';

import ROUTES from '@app/router/Routes';

import DashboardActivityTimeline from './DashboardActivityTimeline';

interface Props {
    className?: string;
}

const DashboardActivityManagerAutosphereContact = ({ className }: Props) => {
    const { data, loading } = useFetch({
        fetchAction: getDashboardPendingCounters,
        resultInterceptor: (response) => response,
        autoFetch: true,
    });
    const { result: pendingCounters } = data;

    return (
        <div className={classNames('dashboard-activity mt-xxl-0', className)}>
            <div className="d-flex align-items-center">
                <i className="dashboard-activity__icon las la-2x la-sitemap mr-2" />
                <span className="font-size-lg font-weight-bold">
                    <FormattedMessage id="DASHBOARD.ACTIVITY.TITLE" />
                </span>
            </div>
            {loading ? (
                <Skeleton
                    borderRadius="10px"
                    className="dashboard-lead__total d-flex flex-row align-items-end mt-5 p-15"
                />
            ) : (
                <Link
                    className="dashboard-lead d-flex flex-row align-items-end mt-5"
                    to={{
                        pathname: routeTo(ROUTES.APV_LEADS.PATH, {
                            statusType: 'new',
                        }),
                    }}
                >
                    <div className="dashboard-lead__total font-weight-bold">{pendingCounters?.apv}</div>
                    <div className="dashboard-lead__title font-size-h4 line-height-md">
                        <FormattedHTMLMessage
                            id="DASHBOARD.ACTIVITY.LEAD.APV.NOT.TAKEN"
                            values={{ number: pendingCounters?.apv }}
                        />
                    </div>
                </Link>
            )}
            <DashboardActivityTimeline />
        </div>
    );
};

export default DashboardActivityManagerAutosphereContact;
