// library imports
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';

import { Locale } from '@src/_metronic/i18n/I18nProvider';

/**
 * @description checks if a mobile number starts with the right digits
 *
 * @param {string} value
 * @returns boolean
 */
/**
 * @description checks if a mobile number starts with the right digits
 *
 * @param {string} value
 * @returns boolean
 */
export const isMobile = (value: string): boolean => ['06', '07'].some((prefix) => value.startsWith(prefix));

/**
 *
 * @description checks if a phone number starts with the right digits
 *
 * @param {string} value
 * @returns boolean
 */
export const isPhone = (value: string) =>
    ['01', '02', '03', '04', '05', '09'].some((prefix) => value.startsWith(prefix));

/**
 * @description adds phone validation
 *
 * @param {string} value
 * @param locale
 * @returns number
 */
export const parsedPhoneNumber = (value: string, locale: CountryCode = Locale.toUpperCase()) =>
    parsePhoneNumberFromString(value || '', locale);

/**
 * @description checks if a string has anything else than number and +
 *
 * @param {string} value
 * @returns string
 */
export const isNotDigit = (value: string) => value?.match(/[^0-9|+]/g);

export const phoneFormatter = (value: string, locale: CountryCode = Locale.toUpperCase()) => {
    const phoneNumber = parsedPhoneNumber(value || '', locale);

    if (!phoneNumber.isValid()) {
        return null;
    }

    return phoneNumber.formatNational();
};
