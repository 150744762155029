import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFetch from '@app/hooks/useFetch';

import { isObject } from '@app/helpers/ObjectHelper';
import { capitalize } from '@app/helpers/StringHelper';
import { empty } from '@app/helpers/ToolsHelper';

import { getHistory } from '@app/crud/account/account.crud';

import HoCDataTable from '@app/partials/content/HoCDataTable';
import Loader from '@app/partials/content/Loader';

const container = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
    },
};

const AccountHistory = ({ profile }) => {
    const now = dayjs();
    const headerClassName = 'bg-white py-5 px-7 font-size-lg font-weight-bold text-secondary';
    const bodyClassName = 'py-5 px-7';
    const months = new Array(12).fill(undefined).map((value, idx) => now.subtract(idx, 'month'));
    const { fetch, data, loading, error, setData } = useFetch({
        fetchAction: getHistory,
    });

    const [selectedMonth, setSelectedMonth] = useState(months[0]);
    const [historyDialog, setHistoryDialog] = useState(false);
    const [historyDialogHeader, setHistoryDialogHeader] = useState('');
    const [historyElement, setHistoryElement] = useState(null);

    useEffect(() => {
        fetch({
            date: dayjs(selectedMonth).format('YYYY-MM-DD'),
        });
    }, [selectedMonth]);

    const monthTemplate = (option) => (
        <span>
            {capitalize(option.format('MMMM'))} {option.format('YYYY')}
        </span>
    );

    const dateBodyTemplate = (history) => {
        const created = dayjs(history.create_datetime);
        return (
            <div>
                {created.format('DD/MM/YYYY')}
                <br />
                {created.format('[à] HH [h] mm')}
            </div>
        );
    };

    const hideDetail = () => {
        setHistoryElement({});
        setHistoryDialog(false);
    };

    const openDetail = (delta) => {
        const historyArray = [];
        let oldValue = '';
        let newValue = '';
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(delta.detail.values)) {
            if (
                !isObject(value[0]) &&
                !Array.isArray(value[0]) &&
                !isObject(value[1]) &&
                !Array.isArray(value[1]) &&
                (!empty(value[0]) || !empty(value[1]))
            ) {
                if (delta.action === 'delete') {
                    oldValue = value[1]?.toString?.() || '-';
                    newValue = value[0]?.toString?.() || '-';
                } else {
                    oldValue = value[0]?.toString?.() || '-';
                    newValue = value[1]?.toString?.() || '-';
                }

                historyArray.push({ name: key, oldValue, newValue });
            }
        }
        const label = delta.action.toUpperCase();
        setHistoryDialogHeader(`Détail de l'historique : ${label}`);
        setHistoryElement(historyArray);
        setHistoryDialog(true);
    };

    const detailBodyTemplate = (history) => {
        if (!Array.isArray(history.detail.values)) {
            return (
                <Button className="p-2 px-3" variant="primary" onClick={() => openDetail(history)}>
                    <i className="fa fa-eye" />
                    Voir le détail
                </Button>
            );
        }
    };

    const nameBodyTemplate = (history) => (
        <span className="d-flex align-items-center">
            <div className="mr-5 p-5 rounded-circle font-weight-bold font-size-base label label-light-primary">
                {history?.user &&
                    history?.user
                        .split(' ')
                        .map((word) => word[0])
                        .join('')}
            </div>
            <div>
                <div className="font-weight-bold">{history?.user}</div>
                <div>{history.role}</div>
            </div>
        </span>
    );

    const cols = [
        {
            field: 'date',
            header: <FormattedHTMLMessage id="USER.HISTORY.TABLE.HEAD.DATE" />,
            headerClassName,
            bodyClassName,
            style: { width: '25%' },
            body: dateBodyTemplate,
        },
        {
            field: 'name',
            header: <FormattedHTMLMessage id="USER.HISTORY.TABLE.HEAD.NAME" />,
            headerClassName,
            bodyClassName,
            body: nameBodyTemplate,
        },
        {
            field: 'action',
            header: <FormattedHTMLMessage id="USER.HISTORY.TABLE.HEAD.ACTION" />,
            headerClassName,
            bodyClassName,
        },
        {
            field: 'action',
            header: <FormattedHTMLMessage id="AUTOUSER.HISTORY.TABLE.HEAD.DETAIL" />,
            body: detailBodyTemplate,
            headerClassName,
            bodyClassName,
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <motion.div variants={container} initial="hidden" animate="visible" className="account-history">
            {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <div className="mb-7 d-flex align-items-center justify-content-between">
                <div className="font-size-h5 font-weight-bold">
                    {capitalize(
                        Intl.formatMessage(
                            { id: 'USER.PROFILE.HISTORY.TITLE' },
                            { suffix: profile ? 'Mon' : '' },
                        )?.trim?.(),
                    )}
                </div>
                <Dropdown
                    value={selectedMonth}
                    options={months}
                    valueTemplate={() => monthTemplate(selectedMonth)}
                    itemTemplate={monthTemplate}
                    onChange={(e) => setSelectedMonth(e.value)}
                    style={{ height: '45px' }}
                    className="p-dropdown--light-primary"
                    optionLabel="name"
                />
            </div>
            <HoCDataTable
                value={data}
                totalRecords={100}
                removableSort
                emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
            >
                {dynamicColumns}
            </HoCDataTable>
            <Dialog
                visible={historyDialog}
                style={{ width: '850px' }}
                header={historyDialogHeader}
                modal
                className="p-fluid"
                onHide={hideDetail}
            >
                <HoCDataTable value={historyElement}>
                    <Column field="name" header="Champ" />
                    <Column
                        field="oldValue"
                        className="text-truncate"
                        header={Intl.formatMessage({ id: 'AUTOUSER.HISTORY.TABLE.HEADER.OLD_VALUE' })}
                    />
                    <Column
                        field="newValue"
                        className="text-truncate"
                        header={Intl.formatMessage({ id: 'AUTOUSER.HISTORY.TABLE.HEADER.NEW_VALUE' })}
                    />
                </HoCDataTable>
            </Dialog>
        </motion.div>
    );
};

AccountHistory.defaultProps = {
    profile: true,
};

export default AccountHistory;
