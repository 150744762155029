import * as dayjs from 'dayjs';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import KTUtil from '@src/_metronic/_assets/js/util';

import useModal from '@app/hooks/useModal';
import usePermissions from '@app/hooks/usePermissions';

import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';
import { leadTypes } from '@app/store/customers/customers.store';
import { LeadApvRequestTypes } from '@app/store/lead/lead-apv.store';

import { CalendarFilter } from '@app/partials/content/CalendarFilter';
import DropdownFilter from '@app/partials/content/DropdownFilter';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import InputFilter from '@app/partials/content/InputFilter';
import Label from '@app/partials/content/Label';
import Loader from '@app/partials/content/Loader';
import Permission from '@app/partials/content/Permission';

import { getVehiclePresence } from '../customers/Helpers/Customer';
import { Create, CreateAlreadyExist } from '../customers/Modals';
import StatusLead from '../customers/Partials/StatusLead';

import { getLeadFlagsIcon, LEAD_FLAGS } from './Helpers/Flags';
import { checkIdenticalData } from './Helpers/LeadOnlineHelper';
import { getClientBody } from './Helpers/LeadsOnlineHelper';
import useSourceBodyTemplate from './Helpers/UseSourceBody';
import LeadRow from './Partials/LeadRow';

import './lead.scss';

const LeadOnlineDataTable = ({
    statusType,
    items,
    start,
    sortField,
    sortOrder,
    data,
    loading,
    handleFilter,
    handlePagination,
    handleSort,
    actionPermissions = ['LEAD_TAKE_LEAD'],
    facetType,
    showClientProject = false,
}) => {
    const { hasAllPermissions } = usePermissions();
    const [loadingExistedCustomers, setLoadingExistedCustomers] = useState();
    const [showCreateModal, toggleCreateModal] = useModal(false, 'create');
    const [showCreateAlreadyExistModal, toggleCreateAlreadyExistModal, createAlreadyExistParams] = useModal(
        false,
        'create_already_exist',
    );
    const leads = data?.result || [];
    const facets = data?.facets || null;
    const Intl = useIntl();
    const userData = useSelector((state) => state.auth.userData);
    const context = userData?.context;

    const selectTypeDropdown = () => {
        const facetsLeadTypes = facets?.leadTypes
            ? facets.leadTypes
                  .map((leadType) => ({
                      name: leadType.label,
                      key: leadType.key,
                  }))
                  .sort((a, b) => a.name.localeCompare(b.name))
            : [];

        return (
            <DropdownFilter
                optionLabel="name"
                filterBy="name"
                name="leadType"
                options={facetsLeadTypes}
                onChange={handleFilter}
            />
        );
    };

    const selectStatusDropdown = () => {
        const leadStatus = facets?.leadStatus
            ? facets.leadStatus.map((status) => ({
                  name: status.label,
                  key: status.key,
              }))
            : [];

        return (
            <DropdownFilter
                optionLabel="name"
                filterBy="name"
                name="leadStatus"
                options={leadStatus}
                onChange={handleFilter}
            />
        );
    };

    const selectSourceDropdown = () => {
        const leadSources = facets?.leadSources
            ? facets.leadSources.map((leadSource) => ({
                  name: leadSource.label,
                  key: leadSource.key,
              }))
            : [];

        return (
            <DropdownFilter
                optionLabel="name"
                filterBy="name"
                name="leadSource"
                options={leadSources}
                onChange={handleFilter}
            />
        );
    };

    const selectCustomerProject = () => {
        const leadReasons = LeadApvRequestTypes.map((leadReason) => ({
            name: leadReason,
            key: leadReason,
        }));

        return (
            <DropdownFilter
                optionLabel="name"
                filterBy="name"
                name="customerProject"
                options={leadReasons}
                onChange={handleFilter}
            />
        );
    };

    const inputFilter = (name) => <InputFilter name={name} onChange={handleFilter} />;

    const calendarFilter = (name) => <CalendarFilter name={name} selectionMode="range" onChange={handleFilter} />;

    const flagsBodyTemplate = (lead) =>
        Array.isArray(lead?.flags) &&
        lead?.flags.length > 0 && (
            <div className="d-flex flex-wrap">
                {getLeadFlagsIcon(lead).map((flag, index) => (
                    // we use index here because we have nothing else to base key on
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="mt-2" key={`${lead.id}_flag_${index}`}>
                        {flag}
                    </div>
                ))}
            </div>
        );

    const typeBodyTemplate = (lead) => (
        <div className="d-flex flex-column">
            <span>{lead?.leadType?.name ?? '-'}</span>
            {flagsBodyTemplate(lead)}
        </div>
    );

    const sourceBodyTemplate = (lead) => {
        const { getSourceClassName, getSourceTemplate } = useSourceBodyTemplate();
        const sourceRender = getSourceClassName(lead);

        return (
            <>
                <div className={`lead-history__source--${sourceRender}`}>&bull;&nbsp;{getSourceTemplate(lead)}</div>
                {lead?.leadOrigin && lead?.leadSource?.slug !== lead?.leadOrigin?.slug && (
                    <Permission permissions={['LEAD_SHOW_SOURCE']}>
                        <span className="font-italic">{lead?.leadOrigin?.name}</span>
                    </Permission>
                )}
                {lead?.operations &&
                    lead?.operations?.map((operation) => (
                        <Label
                            key={`${lead.id}_${operation.slug}`}
                            className="font-weight-bold label-lg label-light-success"
                        >
                            {operation?.name}
                        </Label>
                    ))}
            </>
        );
    };

    const dealershipBodyTemplate = (lead) => (
        <>
            <div>{lead?.dealership?.usualName || '-'}</div>
            {lead?.leadFinance?.contractEndDate &&
                [leadTypes.fidVn, leadTypes.fidVo].includes(lead?.leadType?.slug) && (
                    <Label className="font-weight-bold label-lg label-light-warning">
                        {Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.END_CONTRACT' })}
                        {dayjs(lead?.leadFinance?.contractEndDate).format('DD/MM/YYYY')}
                    </Label>
                )}
        </>
    );

    const immatBodyTemplate = (lead) => {
        let registration = '';
        let partExchangesIdentifier;

        if (lead.leadElements?.[0]?.vehicle?.registration || lead.leadElements?.[0]?.vehicle?.serialNumber) {
            registration = lead.leadElements[0].vehicle?.registration || lead.leadElements[0].vehicle?.serialNumber;
        }

        if (typeof lead.partExchangesIdentifier === 'string' && lead.partExchangesIdentifier.length > 0) {
            partExchangesIdentifier = lead.partExchangesIdentifier;
        }

        if (!registration && !partExchangesIdentifier) {
            return <FormattedMessage id="LEADS_ONLINE.TABLE.IMMAT.WITHOUT" />;
        }

        return (
            <>
                {registration && (
                    <>
                        <span>
                            {lead.leadElements[0].vehicle?.make} {lead.leadElements[0].vehicle?.model}
                        </span>
                        {KTUtil.isInResponsiveRange('mobile') && <>&nbsp;&bull;&nbsp;</>}
                        {!KTUtil.isInResponsiveRange('mobile') && <br />}
                        <span>{registration}</span>
                        <br className="d-lg-none" />
                        {lead.leadElements[0].vehicle?.operations &&
                            lead?.leadElements[0].vehicle?.operations.map((operation) => (
                                <Label key={operation?.slug} className="font-weight-bold label-lg label-light-primary">
                                    {operation?.name}
                                </Label>
                            ))}
                        {lead.leadElements[0].vehicle?.isSold && <i className="la la-ban la-lg text-danger" />}
                    </>
                )}
                {partExchangesIdentifier && (
                    <div className="mt-2">
                        {lead.partExchangesIdentifier}
                        {!['local_achat_cash', 'achat_cash', 'reprise'].includes(lead.leadType.slug) && (
                            <>
                                &nbsp;
                                <FormattedMessage id="LEADS_ONLINE.TABLE.PART_EXCHANGE" />
                            </>
                        )}
                    </div>
                )}
            </>
        );
    };

    const clientBodyTemplate = (lead) => <span>{getClientBody(lead)}</span>;

    const referentBodyTemplate = (lead) => {
        const isPendingLeadFid =
            ['pending'].includes(lead?.leadStatus?.slug) && ['fid_vn', 'fid_vo'].includes(lead?.leadType?.slug);
        return (
            <span>
                {(statusType === 'new' && !isPendingLeadFid) || (!lead?.ownerFirstname && !lead?.ownerLastname)
                    ? '-'
                    : `${lead?.ownerFirstname} ${lead?.ownerLastname}`}
            </span>
        );
    };

    const createdByBodyTemplate = (lead) => <span>{`${lead?.createdBy?.firstname} ${lead?.createdBy?.lastname}`}</span>;

    const statusBodyTemplate = (lead, withDate = true, params = {}) => {
        let date = lead?.lastStatusUpdate;
        if (
            [
                'in_progress_appointment_to_come',
                'in_progress_relaunch_to_come',
                'in_progress_appointment_confirmed',
                'in_progress_appointment_to_confirm',
            ].includes(lead?.leadStatus?.slug)
        ) {
            date = lead?.scheduleAppointment?.date;
        }

        return (
            <StatusLead
                leadStatusUpdated={withDate && date}
                statusSlug={lead?.leadStatus?.slug}
                statusName={lead?.leadStatus?.name}
                statusSubName={lead?.leadStatus?.subName}
                leadReason={lead?.leadReason}
                leadComment={lead?.result}
                {...params}
                table
            />
        );
    };

    const dateCreatedBodyTemplate = (lead) =>
        lead?.receipted ? dayjs(lead?.receipted).format('DD/MM/YYYY [à] HH[h]mm') : '-';

    const actionsBodyTemplate = (lead) => {
        if (!lead?.contact?.id) {
            return (
                <Permission
                    permissions={actionPermissions}
                    interceptor={() => !!lead?.ownerId}
                    fallback={() => (
                        <Button
                            className="p-2 px-3"
                            variant="primary"
                            onClick={() => checkIdenticalData(lead, false, setLoadingExistedCustomers)}
                        >
                            {Intl.formatMessage({ id: 'TRANSLATOR.SHOW' })}
                        </Button>
                    )}
                >
                    <Button
                        className="p-2 px-3"
                        onClick={() => checkIdenticalData(lead, true, setLoadingExistedCustomers)}
                        variant="success"
                    >
                        <i className="la la-hand-pointer mr-2 pr-0" />
                        {Intl.formatMessage({ id: 'LEADS_ONLINE.ACTION.TAKE' })}
                    </Button>
                </Permission>
            );
        }

        if (!lead?.ownerId) {
            return (
                <Permission
                    permissions={actionPermissions}
                    fallback={() => (
                        <Link
                            to={routeTo(
                                ROUTES.CUSTOMER.PATH,
                                {
                                    id: lead?.contact?.id,
                                },
                                {
                                    action: 'viewLead',
                                    leadId: lead?.id,
                                },
                            )}
                        >
                            <Button className="p-2 px-3" variant="primary">
                                {Intl.formatMessage({ id: 'TRANSLATOR.SHOW' })}
                            </Button>
                        </Link>
                    )}
                >
                    <Link
                        to={routeTo(
                            ROUTES.CUSTOMER.PATH,
                            {
                                id: lead?.contact?.id,
                            },
                            {
                                action: 'take',
                                leadId: lead.id,
                            },
                        )}
                    >
                        <Button className="p-2 px-3" variant="success">
                            <i className="la la-hand-pointer mr-2 pr-0" />
                            {Intl.formatMessage({ id: 'LEADS_ONLINE.ACTION.TAKE' })}
                        </Button>
                    </Link>
                </Permission>
            );
        }

        return (
            <Link
                to={routeTo(
                    ROUTES.CUSTOMER.PATH,
                    {
                        id: lead?.contact?.id,
                    },
                    {
                        action: 'viewLead',
                        leadId: lead?.id,
                    },
                )}
            >
                <Button className="p-2 px-3" variant="primary">
                    {Intl.formatMessage({ id: 'TRANSLATOR.SHOW' })}
                </Button>
            </Link>
        );
    };

    const projectBodyTemplate = (lead) => {
        let customerProject = lead?.customerProject;

        if (!customerProject) {
            return '-';
        }

        // // If lead type is local_apv, remove content within parentheses in `customerProject`
        if (lead?.leadType?.slug === leadTypes.localApv) {
            customerProject = customerProject.replace(/\s*\(.*?\)\s*/g, '');
        }

        return customerProject;
    };

    const vehiclePresentBodyTemplate = (lead) => {
        const vehiclePresent = getVehiclePresence(lead?.leadApv?.vehicleInDealership);
        const vehiclePresentMessage = Intl.formatMessage({ id: vehiclePresent ? 'TRANSLATOR.YES' : 'TRANSLATOR.NO' });

        if (!vehiclePresentMessage) {
            return '-';
        }
        return vehiclePresentMessage;
    };

    const serviceBodyTemplate = (lead) => {
        const customerProject = lead?.leadApv?.service;
        return customerProject || '-';
    };

    return (
        <>
            {loadingExistedCustomers && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            {KTUtil.isInResponsiveRange('mobile') ? (
                <div>
                    {leads?.map?.((lead) => (
                        <LeadRow
                            lead={lead}
                            key={lead.id}
                            setLoadingExistedCustomers={setLoadingExistedCustomers}
                            clientBodyTemplate={clientBodyTemplate}
                            immatBodyTemplate={immatBodyTemplate}
                            statusBodyTemplate={statusBodyTemplate}
                            dateCreatedBodyTemplate={dateCreatedBodyTemplate}
                            flagsBodyTemplate={flagsBodyTemplate}
                            dealershipBodyTemplate={dealershipBodyTemplate}
                        />
                    ))}
                    <Paginator
                        first={start}
                        rows={items}
                        totalRecords={data?.count}
                        onPageChange={(e) => handlePagination(e)}
                    />
                </div>
            ) : (
                <HoCDataTable
                    value={leads}
                    rowClassName={(lead) => {
                        const hasStrongFlag = lead?.flags?.includes?.('strong_commitment');
                        const hasStrongPermission = hasAllPermissions(LEAD_FLAGS?.strong_commitment?.permissions);

                        return {
                            'row-border-success': hasStrongFlag && hasStrongPermission,
                        };
                    }}
                    totalRecords={data?.count}
                    lazy
                    rows={items}
                    start={start}
                    first={start}
                    loading={loading}
                    paginator
                    onFilter={handleFilter}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onPage={handlePagination}
                    onSort={handleSort}
                    paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                    currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                    removableSort
                    filterDisplay="row"
                    rowsPerPageOptions={[25, 50, 100]}
                    emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                >
                    <Column
                        field="leadType"
                        header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.TYPE' })}
                        filter
                        filterElement={selectTypeDropdown()}
                        style={{ width: '10%' }}
                        body={typeBodyTemplate}
                        sortable
                    />
                    {!(context === 'bernard' && ['conseiller-commercial'].includes(userData?.role?.slug)) && (
                        <Column
                            field="leadSource"
                            header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.SOURCE' })}
                            filter
                            filterElement={selectSourceDropdown()}
                            body={sourceBodyTemplate}
                            sortable
                        />
                    )}
                    <Column
                        field="dealership"
                        header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.DEALERSHIP' })}
                        filter
                        filterElement={inputFilter('dealership')}
                        body={dealershipBodyTemplate}
                        sortable
                        style={{ width: '15%' }}
                    />
                    {showClientProject && (
                        <Column
                            field="vehicleInDealership"
                            header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.VEHICLE.IN.DEALERSHIP' })}
                            body={vehiclePresentBodyTemplate}
                        />
                    )}
                    {showClientProject && (
                        <Column
                            field="customerService"
                            header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.SERVICE' })}
                            body={serviceBodyTemplate}
                        />
                    )}
                    {showClientProject && (
                        <Column
                            field="referent"
                            header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.REFERRER' })}
                            body={(lead) => lead.leadApv?.referent || '-'}
                        />
                    )}
                    <Column
                        field="vehicleIdentifier"
                        header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.IMMAT' })}
                        filter={statusType !== 'new'}
                        filterElement={inputFilter('vehicleIdentifier')}
                        style={{ width: '10%' }}
                        body={immatBodyTemplate}
                        sortable
                    />
                    <Column
                        field="contact"
                        header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.CUSTOMER' })}
                        filter={statusType !== 'new'}
                        filterElement={inputFilter('contact')}
                        body={clientBodyTemplate}
                        sortable
                    />
                    {showClientProject && (
                        <Column
                            field="customerProject"
                            header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.REASON' })}
                            filter
                            filterElement={selectCustomerProject()}
                            body={projectBodyTemplate}
                        />
                    )}
                    <Column
                        field="user"
                        header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.REFERENT' })}
                        filter
                        filterElement={inputFilter('user')}
                        body={referentBodyTemplate}
                        sortable
                    />
                    {facetType === 'operator' && (
                        <Column
                            field="createdBy"
                            header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.CREATED_BY' })}
                            filter
                            filterElement={inputFilter('createdBy')}
                            body={createdByBodyTemplate}
                            sortable
                        />
                    )}

                    <Column
                        field="leadStatus"
                        header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.LEAD_STATUS' })}
                        filter
                        filterElement={selectStatusDropdown()}
                        style={{ width: '18%' }}
                        body={statusBodyTemplate}
                        sortable
                    />
                    <Column
                        field="receipted"
                        header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.DATE_CREATED' })}
                        filter
                        filterElement={calendarFilter('receiptedPeriod')}
                        body={dateCreatedBodyTemplate}
                        sortable
                    />
                    <Column
                        field="actions"
                        header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.ACTIONS' })}
                        bodyClassName="text-center"
                        style={{ width: '8.5%' }}
                        body={actionsBodyTemplate}
                    />
                </HoCDataTable>
            )}
            <Create showModal={showCreateModal} setShowModal={toggleCreateModal} />
            <CreateAlreadyExist
                showModal={showCreateAlreadyExistModal}
                setShowModal={toggleCreateAlreadyExistModal}
                params={createAlreadyExistParams}
            />
        </>
    );
};

export default LeadOnlineDataTable;
